:root {
    background-color: var(--purple);
    
}

@keyframes navBtnHoverGlow {
    0% {
        box-shadow: 0 0 0px 0px white;
        opacity: 0;
    }
    100% {
        box-shadow: 0 0 calc(50px + 1em) calc(50px + 1em) white;
        opacity: 0.2;
    }
}
@keyframes arrowBtnHoverGlow {
    0% {
        box-shadow: 0 0 0px 0px white;
        opacity: 0;
    }
    100% {
        box-shadow: 0 0 calc(50px + 5em) calc(50px + 3em) white;
        opacity: 0.2;
    }
}

.navbar {
    margin-top: -20px;
}

.sections-navbar {
    display: flex;
    background-color: transparent;
    justify-content: center;
    height: 10rem;
    gap: 5em;
}

.navbar-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: "NavBtnTxt";
    font-size: clamp(0rem, calc(1rem + 3vw), 5rem);
    text-decoration: none;
    transition: 200ms;
    cursor: pointer;
    transition: 200ms;
}
.navbar-btn::before {
    content: "";
    position: relative;
    left: 50%;
    top: -3%;
    width: 10px;
}
.navbar-btn:hover::before {
    animation: navBtnHoverGlow  0.25s ease forwards;
}
.navbar-btn:hover {
    color: white;
    font-size: clamp(0rem, calc(3rem + 3vw), 7rem);
}

.arrowBack {
    width: 50px;
    position: relative;
    transition: 200ms;
}

.arrow-btn {
    width: 50px;
    position: relative;
    top: 95px;
    left: 50px;
    transition: 200ms;
}
@media (max-width: 748px) {
    .sections-navbar {
        display: flex;
        background-color: transparent;
        justify-content: center;
        height: 10rem;
        gap: 2em;
    }
    .arrow-btn {
        width: 20px;
        position: relative;
        top: 43px;
        left: 30px;
        transition: 200ms;
    }
    .arrowBack {
        width: 30px;
        position: relative;
        transition: 200ms;
    }    
}

.arrow-btn:hover {
    left: 45px;
}
.arrow-btn:hover .arrowBack {
    transform: scale(1.35);
}
.arrow-btn::before {
    content: "";
    position: relative;
    left: 50%;
    top: -3%;
    width: 10px;
}
.arrow-btn:hover::before {
    animation: arrowBtnHoverGlow  0.25s ease forwards;
}

.aboutMainHead {
    position: relative;
    margin-top: 5%;
    margin-left: 8%;
    font-size: 50px;
    font-family: 'VarelaRound';
    border-radius: 2px;
    animation: 
        background-pan 3s linear infinite;
    background: linear-gradient(
        to right,
        var(--blue),
        white
    );
    background-size: 15000%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
}

.aboutHead {
    background-color: var(--blue);
    position: relative;
    margin-top: 10%;
    margin-left: 8%;
    text-align: center;
    font-size: 50px;
    font-family: 'VarelaRound';
    width: 8em;
    padding: 5px;
    border-radius: 5px;
    color: var(--purple);
    font-weight: 600;
}

.aboutTxt {
    position: relative;
    margin-left: 8%;
    width: 80%;
    font-size: 1.5rem;
    font-family: 'VarelaRound';
    text-align: justify;
}

