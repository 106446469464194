:root {
    --blue: #78c1fa;
    --purple: #5458c2;
    --violet: rgb(103, 58, 183);
    --pink: rgb(244, 143, 177);
    --gray: #36393F;
    --darkBlue: #6289E3;
    --darkerBlue: #4C6AB0;
    --section-size: 100vh;
    --title-size: clamp(2rem, 5rem, 10rem);
    --small-screen: 768px;
    --translateAmt: -400;
}

.hide-overflow {
    overflow: hidden;
}

@keyframes background-pan {
	0% {
		background-position: 0% 0%;
	}
	30% {
		background-position: 100% 0%;
	}
	50% {
		background-position: 100% 0%;
	}
	80% {
		background-position: 0% 0%;
	}
    100% {
		background-position: 0% 0%;
    }
}

@keyframes titleFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.4);
    }
    80% {
        transform: scale(1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes titleSlideIn {
    0% {
        left: -50%;
    }
    100% {
        left:  0;
    }
}
@keyframes subtitleSlideIn {
    0% {
        left: -150%;
        opacity: 0;
    }
    20% {
        left: -150%;
        opacity: 0;
    }
    100% {
        left:  0;
        opacity: 1;
    }
}

@keyframes pageTransition {
    0% {
        width: 0vw;
        height: 0vw;
    }
    100% {
        width: calc(200vb + 150vw);
        height: calc(200vb + 150vw);
    }
}
@keyframes pageTransitionTxt {
    0% {
        margin-top: 0px;
        font-size: calc(3rem + 1.5vw);
    }
    10% {
        margin-top: 0px;
        font-size: calc(3rem + 1.5vw);
        color: white;
    }
    100% {
        margin-top: -25px;
        font-size: calc(5rem + 1.5vw);
        color: var(--purple);
    }
}
@keyframes pageTransitionHide {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


.title-section {
    width: 100%;
    background-color: var(--blue);
    height: 100vb;
}

.title {
    position: relative;
    animation: 
        titleSlideIn 1.2s ease forwards, 
        titleFadeIn 1s ease forwards, 
        background-pan 10s linear infinite;
    background: linear-gradient(
        to right,
        var(--purple),
        white
    );
    background-size: 15000%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
}
.title-pos {
    text-align: center;
    top: 10vh;
}

.subtitle {
    position: relative;
    animation: 
        subtitleSlideIn 1.8s ease forwards, 
        titleFadeIn 1s ease forwards, 
        background-pan 10s linear infinite;
    background: linear-gradient(
        to right,
        var(--purple),
        white
    );
    background-size: 15000%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
    margin-top: 10px;
}
.subtitle-pos {
    text-align: center;
    top: 8vh;
}

.name {
    font-size: clamp(0rem, calc(1rem + 4vw), 6rem);
    font-weight: 1000;
}
.software-dev {
    font-size: clamp(0rem, calc(.7rem + 3vw), 4rem);
}


.btns {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 9vh;
    margin-top: calc(31vh + -7vw);
}
.btn {
    border: 5px transparent solid;
    font-size: calc(3rem + 1.5vw);
    position: relative;
    width: 5em;
    height: 1.5em;
    text-align: center;
    background-color: white;
    border-radius: 50px;
    cursor: pointer;
    top: 0px;
    font-family: 'NavBtnTxt';
    box-shadow: 0 0px 5px white;
    color: var(--purple);
    transition: 300ms;
    
}
.btn:hover {
    background-color: var(--purple);
    box-shadow: 0 0px 50px var(--purple);
    color: white;
    width: clamp(5em, 70%, 10em);
}

.btn-txt {
    position: relative;
    z-index: 1;
    position: relative;
    top:50%;
    left:50%;
    transform: translate(-50%, -45%);
}

.btn-container {
    width: clamp(5rem, 20rem, 20rem);
    height: clamp(5rem, 20rem, 20rem);
    position: relative;
    background-color: transparent;
    transition: 200ms;
}

.btn-container > .btn-content {
    font-size: 50px;
    color: var(--purple);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
}

.click-anim {
    color: white;
    animation: pageTransitionTxt 1.8s ease forwards;
}

.click-anim::before {
    content: "";
    position: absolute;
    background-color: var(--purple);
    border-radius: 50%;
    display: inline-block;
    width: 5vw;
    height: 5vw;
    z-index: 1;
    border-width: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pageTransition 2s ease forwards;
}

.hide {
    animation: pageTransitionHide 1s ease forwards;
}