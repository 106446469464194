html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.projectsContent {
    margin-top: 100px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    
}

.project {
    width: 25%;
    height: 300px;
    background-color: var(--darkBlue);
    border: 2px var(--darkerBlue) solid;
}
.project:hover {
    background-color: var(--darkerBlue);
    cursor: pointer;
}

.project > .hoverIcon {
    opacity: 0;
    color: white;
    font-size: 20px;
    text-align: center;
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--purple);
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 17px;
    font-family: 'JosefinSans';
    padding-top: 80px;
    transition: 400ms;
}
.project:hover > .hoverIcon {
    opacity: 1;
    color: white;
    font-size: 20px;
    text-align: center;
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--purple);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 30px;
    font-family: 'JosefinSans';
    line-height: 1;
}

.projectPopup {
    z-index: 10;
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 80%;
    background-color: var(--darkerBlue);
}

.closeBtn {
    z-index: 1;
    position: absolute;
    top: 12px;
    right: 15px;
    font-size: 30px;
    line-height: 32px;
    font-family: 'VarelaRound';
    font-weight: 1000;
    border: 3px darkgray solid;
    text-align: center;
    background-color: darkgray;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: 200ms;
}

.closeBtn:hover {
    border: 3px gray solid;
    cursor: pointer;
    
}

.project1 {
    background-color: blue;
}

.behind {
    z-index: -10;
}

.hideFast {
    opacity: 0;
}

@media (max-width: 5000px) {
    .project {
        width: 15%;
    }
}
@media (max-width: 1820px) {
    .project {
        width: 20%;
    }
}
@media (max-width: 1380px) {
    .project {
        width: 25%;
    }
}
@media (max-width: 1080px) {
    .project {
        width: 33.33%;
    }
}
@media (max-width: 800px) {
    .project {
        width: 50%;
    }
}

